<template>
  <div class="mindReservation" ref="mindReservation">
    <div class="mindContent">
      <deviceBorrowItem
          class="mindReservationBorrow"
          loaddingClass="mindReservationBorrow"
          :rowKeyList="rowKeyList"
          :keyList="keyList"
          :queryType="1"
      ></deviceBorrowItem>
    </div>
    <!-- <div class="addReservation" @click="addReservation">
      <i class="el-icon-circle-plus-outline"></i> 添加预约
    </div> -->
  </div>
</template>

<script>
import deviceBorrowItem from '@/components/deviceBorrowItem'

export default {
  name: 'mindReservation',
  components: { deviceBorrowItem },
  data() {
    return {
      keyList: [
        {
          key: 'orderId',
          name: '预约单号',
          space: '21px',
          float: 'right',
          color: '#3E73FB'
        },
        {
          key: 'orderTime',
          name: '预约时间',
          space: '21px',
          float: 'right',
          color: '#3E73FB'
        }
      ],
      rowKeyList: [
        { key: 'brandName', name: '品 牌', space: '21px', float: 'right' },
        { key: 'modelName', name: '型 号', space: '21px', float: 'right' },
        { key: 'code', name: this.$VUEAPPDEVICECODE(), space: '21px', float: 'right' },
        { key: 'label', name: '卡 片 号', space: '3px', float: 'right' },
        {
          key: 'categoryName',
          name: '设备分类',
          space: '21px',
          float: 'right'
        }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    addReservation() {
      this.$push('deviceBorrow/reservationList', {})
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.mindReservation {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .mindContent {
    height: 100%;
  }

  .addReservation {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: calc(100% - 20px);
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #fff;
    margin: 0 10px;
    background: #3e73fb;
    border-radius: 2px;
    font-size: 16px;
    border-radius: 20px;
  }
}
</style>
